import { isEmpty, isSet } from "lodash";

export default {

    init(){
        this.validateData();
        this.stepNavigation();
        this.inputMasks();
        this.nextStep();
        //this.checkZipCode();
        this.selectServiceParameters();
        this.changeAddress();
        this.openModal();
        this.showPassword();
        this.loginSilent();
        this.lastStep();

        var uid ='';

        if (this.isMobileDevice()) {
            $("#device").val('Mobile Website');
            $("#sign_up").val('Mobile Website');
        } else {
            $("#device").val('Desktop Website');
            $("#sign_up").val('Desktop Website');
        }
        if(location.pathname == '/order-page'){
            this.logStep('step1');
        }
    },

    inputMasks(){
        jQuery(function($) {
            $.mask.definitions['9'] = '';
            $.mask.definitions['d'] = '[0-9]';
            $("#zipcode").mask("ddddd");
            $("#expir").mask("dd/dd");
            $("#exp_date").mask("dd/dd");
            $("#phone").mask("ddd-ddd-dddd");
            $("#tips").mask("?$dd");
            $("#CardNumber").mask("?dddd dddd dddd dddd");
            $("#card_number").mask("?dddd dddd dddd dddd");
        });
    },

    validateData(){
        var $this = this;
        $("#phone").click(function () {
            if(parseInt(this.value)>0){}else{this.setSelectionRange(0, 0);}
        });
        $("#CardNumber").click(function () {
            if(parseInt(this.value)>0){}else{this.setSelectionRange(0, 0);}
        });
        $("#expir").click(function () {
            if(parseInt(this.value)>0){}else{this.setSelectionRange(0, 0);}
        });
        $('#address').change(function() {
            if(this.value == ''){
                $(this).css('border-color', 'red');
            }else{
                $(this).css('border-color', '');
            }
        });
        $('#apartment').change(function() {
            if(this.value == ''){
                $(this).css('border-color', 'red');
            }else{
                $(this).css('border-color', '');
            }
        });
        $("#zipcode").click(function () {
            if(parseInt(this.value)>0){}else{this.setSelectionRange(0, 0);}
        });
        $('#zipcode').on('blur', this.checkZipCode);
        $("#phone").blur({uid: $this}, $this.storeLeadInfo);
        $("#form-email").blur({uid: $this}, $this.storeLeadInfo);

    },

    stepNavigation(){
        $('.navigation-btn').click(function() {
            var step_name = $(this).data('step_name');
            if ($('#'+step_name).hasClass('change')) {
                $(this).addClass('focused');
                $(this).removeClass('unfocused')


                $('.show').addClass('hide');
                $('.show').removeClass('show');

                $('#'+step_name).addClass('show');
                $('#'+step_name).removeClass('hide');
                var step_number = $(this).data('step_number');
                var nav_btns = document.getElementsByClassName('navigation-btn');
                for (const i of nav_btns) {
                    if (i.dataset['step_number']>step_number) {
                        i.classList.remove('focused');
                        i.classList.add('unfocused');
                    }else if(i.dataset['step_number']<step_number){
                        i.classList.add('focused');
                        i.classList.remove('unfocused');
                    }
                }
            }

        });
    },

    nextStep(){
        var $this = this;
        $('.next-btn').click(function() {
            //console.log('step: '+$(this).data('step'));
            var step = $(this).data('step');
            var nav_btn = $('.navigation-btn')[step];
            var last_nav= $('.navigation-btn')[step-1];
            if(step==1){

                if($('#address').val()=='' || $('#apartment').val()=='' || $('#zipcode').val()==''){
                    if ($('#address').val()=='') {
                        $('#address').css('border-color', 'red');
                    }else{
                        $('#address').css('border-color', '');
                    }
                    if($('#apartment').val()==''){
                        $('#apartment').css('border-color', 'red');
                    }else{
                        $('#apartment').css('border-color', '');
                    }
                    if($('#zipcode').val()==''){
                        $('#zipcode').css('border-color', 'red');
                    }else{
                        $('#zipcode').css('border-color', '');
                    }
                }else{
                    $this.checkZipCode();
                    $('#address').css('border-color', '');
                    $('#apartment').css('border-color', '');
                    $('#zipcode').css('border-color', '');

                    $('#location').removeClass('show');
                    $('#location').addClass('hide');

                    if(!$('#user_id').val()>0){
                        let sendingDataLead = {
                            'uid': $this.getUid(),
                            'address': $('#address').val(),
                            'apartment': $('#apartment').val(),
                            'zip_code': $('#zipcode').val(),
                            //'city': $('#state-form').val(),
                            //'state': $('#state-code-form').val(),
                            'source': 'web'
                        }
                        $.post('/lead-store', sendingDataLead, function (response) {
                            console.log('send lead: ', sendingDataLead, response);
                        });
                    }

                    $('#services').addClass('show');
                    $('#services').removeClass('hide');
                    nav_btn.classList.remove('unfocused');
                    nav_btn.classList.add('focused');
                    last_nav.classList.add('hide-mobile');
                    nav_btn.classList.remove('hide-mobile');
                    $('#services').addClass('change');
                    $this.logStep('step2');
                }
            }else if(step==2){
                $('#services').addClass('hide');
                $('#services').removeClass('show');

                $('#time').addClass('show');
                $('#time').removeClass('hide');
                nav_btn.classList.remove('unfocused');
                nav_btn.classList.add('focused');
                if($('#user_id').val()>0){
                    $('.navigation-btn-mobile').addClass('focused');
                }else{
                    last_nav.classList.add('hide-mobile');
                    nav_btn.classList.remove('hide-mobile');
                }
                $('#time').addClass('change');
                $this.logStep('step3');
            }else if(step==3){
                if($('#user_id').val()>0){
                    $this.createOrder();
                }else{
                    $('#time').addClass('hide');
                    $('#time').removeClass('show');

                    $('#account').addClass('show');
                    $('#account').removeClass('hide');
                    nav_btn.classList.remove('unfocused');
                    $('.navigation-btn-mobile').addClass('focused');
                    nav_btn.classList.add('focused');
                    $('#account').addClass('change');
                    $this.logStep('step4');
                }
            }else if(step==4){
                console.log('Create order');
                if($('#firstname').val()==''){
                    $('#firstname').css('border-color', 'red');
                    return false;
                }else{
                    $('#firstname').css('border-color', '');
                }
                if($('#lastname').val()==''){
                    $('#lastname').css('border-color', 'red');
                    return false;
                }else{
                    $('#lastname').css('border-color', '');
                }
                if($('#form-email').val()==''){
                    $('#form-email').css('border-color', 'red');
                    return false;
                }else{
                    let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
                    if (!regex.test($('#form-email').val())) {
                        $('#form-email').css('border-color', 'red');
                        return false;
                    }else{
                        $('#form-email').css('border-color', '');
                    }
                }
                if($('#phone').val()==''){
                    $('#phone').css('border-color', 'red');
                    return false;
                }else{
                    $('#phone').css('border-color', '');
                }
                if($('#form-password').val()==''){
                    $('#form-password').css('border-color', 'red');
                    return false;
                }else{
                    $('#form-password').css('border-color', '');
                }
                if($('#CardNumber').val()==''){
                    $('#CardNumber').css('border-color', 'red');
                    return false;
                }else{
                    $('#CardNumber').css('border-color', '');
                }
                if($('#expir').val()==''){
                    $('#expiration').css('border-color', 'red');
                    return false;
                }else{
                    $('#expiration').css('border-color', '');
                }

                $this.createOrder();


            }
            $this.ScrollToTop();

        });
    },

    checkZipCode(){
        /* $('#zipcode').on('blur' ,function() { */
            var zipcode = $('#zipcode').val();
            var area = '';
            if (!zipcode.includes("_")) { //10041,10075, 10105, 10118, 10122, 10111, 10121, 10131, 10133, 10152, 10153, 10154, 10166, 10167, 10169, 10172, 10211, 10213, 10280, 10281, 10282, 10271,
                var zipcodes_ok = settings['allow_zip_codes'].split(',');
                    // [10001, 10002, 10003, 10004, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012,
                    // 10013, 10014, 10015, 10016, 10017, 10018, 10019, 10020, 10021, 10022, 10023, 10024, 10025, 10026,
                    // 10028, 10128, 10029, 10038, 10036, 10065, 10041,10075, 10081, 10105, 10107, 10118, 10120, 10122,
                    // 10111, 10120, 10121, 10131, 10133, 10138, 10152, 10153, 10154, 10166, 10167, 10169, 10172, 10211,
                    // 10213, 10280, 10281, 10282, 10271, 11251, 11201, 11206, 11231, 11217, 11225, 11238, 11216, 11215,
                    // 11211, 11206, 11249, 11205, 11222]; //, 11251, 11201, 11206, 11231, 11217, 11238, 11216, 11225, 11215, 11211, 11206, 11249
                for (var i = 0; i <= zipcodes_ok.length; i++) {
                    if (zipcode == zipcodes_ok[i]) {
                        area = 'good';
                        break;
                    }
                }
                if (area == 'good') {
                    $('#zip_error').html('');
                    $('#zip_error').css('display', "none");
                    $('#zipcode').css('border-color', '#A7A7A7');
                    $('#zipcode').css('color', '#520000');
                    $('.next-btn').removeAttr('disabled');
                    return true;
                } else {
                    $('#zip_error').html('sorry you are outside of our <span style="text-decoration: underline; cursor: pointer; color: red;" onclick="document.getElementById(\'service-area-modal\').setAttribute(\'data-modal-state\',\'open\')">service area</span>');
                    $('#zip_error').css('display', "block");
                    $('#zipcode').css('border-color','red');
                    $('#zipcode').css('color', 'red');
                    $('.next-btn').attr('disabled','disabled');
                    //Order.ZipcodeLogs(zipcode);
                    return false;
                }
            }
        /* }); */
    },

    selectServiceParameters(){
        var $this = this;
        $('.switch-btn').click(function() {
            if (!$(this).hasClass('active')) {
                //console.log('this');
                $(this).parent().find('.active').removeClass('active');
                $(this).addClass('active');
                $this.WashOrder($(this).data('id'));
            }
        });
        $('.switch-btn-dryclean').click(function() {
            if (!$(this).hasClass('active')) {
                //console.log('this');
                $(this).parent().find('.active').removeClass('active');
                $(this).addClass('active');
                $this.WashOrder($(this).data('id'));
                var status_name = $(this).data('status_name')
                if($(this).text() == 'Nope'){
                    $('.status.'+status_name).text('unselected');
                    if(status_name == 'wash'){
                        if($('.dry.active').text() == 'Nope'){
                            var dry = $('.dry.active').parent().find('.dry');
                            dry[0].classList.add('active');
                            dry[1].classList.remove('active');
                            $('.status.dry').text('selected');
                            $this.WashOrder($('.dry.active').data('id'));
                        }
                    }else if(status_name == 'dry'){
                        if($('.wash.active').text() == 'Nope'){
                            var wash = $('.wash.active').parent().find('.wash');
                            wash[0].classList.add('active');
                            wash[1].classList.remove('active');
                            $('.status.wash').text('selected');
                            $this.WashOrder($('.wash.active').data('id'));
                        }
                    }
                }else{
                    $('.status.'+status_name).text('selected');
                }
            }
        });
    },

    openModal(){
        $('#view_pricing_btn').click(function() {
            console.log('success');
            $('#price-modal').attr('data-modal-state', 'open');
        });
        $('#have_acc_btn').click(function() {
            $('#login-modal').attr('data-modal-state', 'open');
        });
        $('.modal-close').click(function() {
            $(this).parent().parent().attr('data-modal-state', 'close');
        });
        $('.close-modal-btn').click(function() {
            location.href = '/';
        });
    },

    changeAddress(){
        $('#change-addr-btn').click(function () {
            try {
                $('#doorman').removeAttr('disabled');
                $('#hotel').removeAttr('disabled');
                $('#address').removeAttr('disabled');
                $('#apartment').removeAttr('disabled');
                $('#zipcode').removeAttr('disabled');

                document.getElementById('change-address-h').value=1;
                console.log('inputs enabled');
                /* Order.LogStep('inputs enabled'); */
            } catch (error) {
                console.log(error);
            }
        });
    },

    showPassword(){
        $('#show_pass').click(function() {
            if($(this).is(':checked')){
                $('#form-password').attr('type', 'text');
            }else{
                $('#form-password').attr('type', 'password');
            }
        });
    },

    createOrder(){
        var $this =this;
        if($('#user_id').val()>0){

        }else{

            let sendingDataLead = {
                'uid': $this.getUid(),
                'email': $('#form-email').val(),
                'source': 'web',
                'address': $('#address').val(),
                'apartment': $('#apartment').val(),
                'zip_code': $('#zipcode').val(),
                'first_name': $('#firstname').val(),
                'last_name': $('#lastname').val()
            }
            /** создание заказов открыто */
            /* $.post('/lead-store-total', sendingDataLead, function (response) {
                console.log('send lead: email ', sendingDataLead, response);
            }); */
        }

        let service_id;
        if($('#service').val() === 'wash'){
            service_id = 1;
        }else if($('#service').val() === 'dryclean') {
            service_id = 2;
        }

        let sendingData = {
            'uid': $this.getUid(),
            'order': {
                'service_id': service_id,
                'tips': $('#tips').val().split('$').join(''),
                'customer_comment': $('#customer_comm').val(),
                'device': '',
                'pickup_date': $('#pickup_time').val(),
                'dropoff_date': $('#dropoff_time').val(),
                'living_wage_fee': $('#safety_fee').val(),
                'congestion_fee': $('#congestion_fee').val(),
            }
        }
        let exp_date = $('#expir').val();
        if ($('#service').val() == "dryclean") {
            sendingData.order.attributes = {
                'dryclean': $("#dryclean").val(),
                'washing': $("#washing").val(),
                'laundrybag': $("#laundrybag").val(),
                'launder_shirt': 0,
                'pants': 0,
                'shirt': 0,
                '2pc_suit': 0,
                '3pc_suit': 0,
                'short_coat': 0,
                'regular_coat': 0,
                'shorts_skirt': 0,
                'sweater_blouse': 0,
                'blaser_suit_jacket': 0,
                'dress': 0,
                'scarf_tie': 0,
            }
        } else if ($('#service').val() == "wash") {
            sendingData.order.attributes = {
                'detergent': $('#detergent').val(),
                'softener': $('#softener').val(),
                'bleach_whites': $('#bleach').val(),
                'laundrybag': $('#laundrybag').val(),
            }
        }

        if (document.getElementById('user_id').value > 0 && document.getElementById('change-address-h').value == 0) {
            sendingData['user'] = null
        } else if(document.getElementById('user_id').value > 0 && document.getElementById('change-address-h').value == 1){
            sendingData['user'] = {
                'user_id': document.getElementById('user_id').value,
                'address': document.getElementById('address').value,
                'apartment': document.getElementById('apartment').value,
                'zip_code': document.getElementById('zipcode').value,
                'doorman': (document.getElementById('doorman').value === 'on' ? 1 : 0),
                'change-address-h': document.getElementById('change-address-h').value,
            }
        } else {
            sendingData['user'] = {

                'email': $('#form-email').val(),
                'password': $('#form-password').val(),

                'first_name': $('#firstname').val(),
                'last_name': $('#lastname').val(),
                'phone': $('#phone').val(),

                'address': $('#address').val(),
                'apartment': $('#apartment').val(),
                'city': $('#state').val(),
                'state': $('#state-code').val(),
                'zip_code': $('#zipcode').val(),
                'doorman': ($('#doorman').prop('checked') === true ? 1 : 0),
                //'hotel': ($this.getCookie('#hotel_user') == 1 ? 1 : 0),
                'hotel': $('#hotel').is(':checked') ? 1 : 0,

                'card_number': $('#CardNumber').val(),
                'card_type': $('#cardtype').val(),
                'exp_date': $('#expir').val(),

                'card_exp_month': exp_date.split('/')[0],
                'card_exp_year': exp_date.split('/')[1],

                'card_cvc': 123,

                'lead_source': $this.getCookie('lead_source'),
                'device': $('#device').val()
            };
        }
        //$('#wait-modal').attr('data-modal-state', 'open');
        if($('#user_id').val()>0){
            $this.logStep('step4');
        }else{
            $this.logStep('step5');
        }
        //return false;

        $.post('/order/create', sendingData, function (response) {

            if (response && response.status) {

                //Order.LogStep('order-received');

                console.log('Order Create OK', response);

                $.post('/lead-clean', { 'uid': $this.getUid() }, function (response) {
                    console.log('delete lead after login: ', response);
                });

                $('#success-modal').attr('data-modal-state', 'open');

            }

        }).fail((responseError) => {
            console.log(responseError);
        });
    },

    getUid(){
        if ($("#user_id").val() > 0) {
            this.uid = '';
            return this.uid;
        }

        if (!this.uid) {

            let timestamp = Math.floor(Date.now() / 1000)
            let randomValue = ('00' + Math.floor((Math.random() * 1000))).slice(-3);
            this.uid = timestamp + '' + randomValue
        }

        return this.uid;
    },

    WashOrder(id){
        if (id < 4) {
            $('#detergent').val(id);
        } else if (id < 8) {
            $('#softener').val(id - 3);
        }
        else {
            if (id == 9 || id == 10) {
                $('#dryclean').val(id - 9);
            }
            if (id == 11 || id == 12) {
                $('#washing').val(id - 11);
            }
            if (id == 13 || id == 14) {
                $('#laundrybag').val(id - 13);
            }
            if(id == 15 || id == 16) {
                $('#bleach').val(id - 15);
            }
        }
    },

    ScrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },

    storeLeadInfo(uid){
        if($('#user_id').val()>0){
            return;
        }
        // send lead email
        if ($('#form-email').val() || $('#phone').val()) {

            let sendingData = {
                'uid': uid.data.uid.getUid(),
                'phone': $('#phone').val(),
                'email': $('#form-email').val(),
                'source': 'web',
                'first_name': $('#firstname').val(),
                'last_name': $('#lastname').val()
            }
            $.post('/lead-store', sendingData, function (response) {
                console.log('send lead email: ', sendingData, response);
            });
        }
    },

    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },

    isMobileDevice() {
        var check = false;
        (function(a){
            if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0,4)))
            check = true;
        })(navigator.userAgent||navigator.vendor||window.opera);
        return check;
    },
    loginSilent(){
        var $this = this;
        //document.getElementById("button-silent-login").style.visibility = "hidden";
        $('#button-silent-login').click(function() {
            $.post('/login', {
                'email': $("#email").val(), //username
                'password': $("#password").val()
            }, 'json').done(function ( response ) {

                $.post('/lead-clean', { 'uid': $this.getUid() }, function (response) {
                    console.log('delete lead after login: ', response);
                });
                console.log($this.uid);
                $this.uid = '';
                console.log($this.uid);


                /* $('#user_id').val(response.id);

                $('#address').val(response.address);

                $('#apartment').val(response.apartment);

                $('#zipcode').val(response.zip_code);

                $('#firstname').val(response.first_name);
                $('#lastname').val(response.last_name);
                $('#phone').val(response.phone); */

                $("#login-modal").attr("data-modal-state","close");

                location.reload();

            }).fail(function( response ) {
                $("#error-message-login").innerHTML = "Oops. Something went wrong on our side. Ply try again later.";
            });
        });
    },

    lastStep(){
        $('.back-btn').click(function () {
            //console.log('go back', $(this).data('step-name'));
            var step_name = $(this).data('step-name');
            if(step_name=='home'){
                location.href ='/';
            }else{
                var step_number = '';
                $('.show').addClass('hide').removeClass('show');
                $('#'+step_name).removeClass('hide').addClass('show');
                if(step_name == 'location'){
                    step_number = 0;
                }else if(step_name == 'services'){
                    step_number = 1;
                }else if(step_name == 'time'){
                    step_number = 2;
                    $('.navigation-btn-mobile').removeClass('focused');
                }
                var nav_btns = document.getElementsByClassName('navigation-btn');
                for (const i of nav_btns) {
                    if (i.dataset['step_number']>step_number) {
                        i.classList.remove('focused');
                        i.classList.add('unfocused');
                    }else if(i.dataset['step_number']<step_number){
                        i.classList.add('focused');
                        i.classList.remove('unfocused');
                    }
                    if($('#user_id').val()>0 && i.dataset['step_name']!='time'){
                        if(i.dataset['step_name'] == step_name){
                            i.classList.remove('hide-mobile');
                        }else{
                            i.classList.add('hide-mobile');
                        }
                    }else if ($('#user_id').val()=='' && i.dataset['step_name']!='account'){
                        if(i.dataset['step_name']== step_name){
                            i.classList.remove('hide-mobile');
                        }else{
                            i.classList.add('hide-mobile');
                        }
                    }
                }
            }
        })
    },
    logStep(step){

        if(typeof(dataLayer)!='undefined'){
            if (document.getElementById('user_id').value > 0) {
                dataLayer.push({
                    'event': 'user-' + step
                });
                if (typeof hj === "function") {
                    hj('stateChange', '?step=user-' + step);
                }
                console.log("LOGGING STEP user-" + step);
            } else {
                dataLayer.push({
                    'event': step
                });
                if (typeof hj === "function") {
                    hj('stateChange', '?step=' + step);
                }
                console.log("LOGGING STEP " + step);
            }
        }
        console.log(step);
    },
}
