export default {

    init(){
        this.getTime();
    },

    getService(){
        var url_string = window.location.href; //window.location.href
        var url = new URL(url_string);

        var service = url.searchParams.get("service");
        return service;
    },

    getTime(){
        let $this = this;
        var wash = {
            'washFold': 1,
            'dryCleaning': 2,
            'homeCleaning': 3
        };
        var globalData = {
            'goBack': 'washFold'
        };
        var service = this.getService();

        if (service == 'dryclean') {

            globalData = {
                'goBack': 'dryCleaning'
            };

        } else if (service == 'wash') {
            globalData = {
                'goBack': 'washFold'
            };
        }

        $.get('/time-schedule', { 'wash': wash[globalData['goBack']] }, function (r) {

            var PickUp = r;
            let cacheFirstTimeDate = PickUp[0]["day_info"]["value"] + " " + PickUp[0]["work_times"][0]["value"];

            for (let i in PickUp) {
                $('#pickup_day').append('<option value="'+i+'">'+PickUp[i]['day_info']['text'] +'</option>')
            }
            for (const i in PickUp[0]["work_times"]) {
                $('#pickup_time').append('<option value="'+PickUp[0]["day_info"]["value"]+' '+PickUp[0]["work_times"][i]["value"]+'">'+PickUp[0]["work_times"][i]["text"] +'</option>')
            }

            $.get('/time-schedule', { 'pickup': cacheFirstTimeDate, 'wash': wash[globalData['goBack']] }, function (d) {
                // DropOff = JSON.parse(d.replace(/\,\]/gm, "]"));
                var DropOff = d;
                //console.log(DropOff);
                for (let i in DropOff) {
                    $('#dropoff_date').append('<option value="'+i+'">'+DropOff[i]["day_info"]["text"]+'</option>')
                }
                for (let i in DropOff[0]["work_times"]) {
                    $('#dropoff_time').append('<option value="'+DropOff[0]["day_info"]["value"]+' '+DropOff[0]["work_times"][i]["value"]+' ">'+DropOff[0]["work_times"][i]["text"]+'</option>');
                }

                //day-start
                if ($('#pickup_day').length > 0) {
                    //$('#pickup_day').change(styledSelector);
                    //styledSelector.call($('.time-page-form [name="day-start"]')[0]);
                    $('#pickup_day').change(function () {
                        var val = $(this).val(); var time = PickUp[val], options='';
                        for (var i in time["work_times"]){
                            options += '<option value="' + time["day_info"]["value"] + ' ' + time["work_times"][i]["value"] + '"> ' + time["work_times"][i]["text"] + '</option>';
                        }
                        $('#pickup_time').html(options);
                        $this.chengeDropOffDate($('#pickup_time').val(), globalData, wash);
                        //styledSelector.call($('#pickup_time')[0]);
                    });
                }

                //time-start
                if ($('#pickup_time').length > 0) {
                    //$('#pickup_time').change(styledSelector);
                    //styledSelector.call($('#pickup_time')[0]);
                    $('#pickup_time').change(function () {
                        $this.chengeDropOffDate($('#pickup_time').val(), globalData, wash);
                        //styledSelector.call($('#dropoff_time')[0]);
                    });
                }

                //day-end
                if ($('#dropoff_date').length > 0) {
                    //$('.#dropoff_date').change(styledSelector);
                    //styledSelector.call($('.#dropoff_date')[0]);
                    $('#dropoff_date').change(function () {
                        var val = $(this).val(); var time = DropOff[val], options = '';
                        for (var i in time["work_times"]) options += '<option value="' + time["day_info"]["value"] + ' ' + time["work_times"][i]["value"] + '"> ' + time["work_times"][i]["text"] + '</option>';
                        $('#dropoff_time').html(options);
                        //styledSelector.call($('#dropoff_time')[0]);
                    });
                }

                //time-end
                if ($('#dropoff_time').length > 0) {
                    //$('#dropoff_time').change(styledSelector);
                    //styledSelector.call($('#dropoff_time')[0]);
                }

               /*  function selectFix(){
                    $('select').map(function(){
                        //styledSelector.call(this);
                    });

                }


                $('.step-end').click(function () {
                    var cache = getFormData();
                    globalData['data_time_info'] = cache;
                });

                //if(cordova.platformId!='android') $('.time-page .first-select,.time-page .second-select').css({width:'100%','margin-bottom':'10px'})

                $('.time-page .selected-items-body select').map(function(){
                    $(this).css({'width':$(this).parent().width()+'px'});
                });
                if (typeof checkDates === 'function') {
                    checkDates();
                } */
            });

        });

    },

    chengeDropOffDate(val, globalData, wash) {
        //console.log('showing schedule for ' + globalData['goBack']);
        if(typeof parse_query_string !== 'undefined' && typeof parse_query_string === 'function'){
            var query = window.location.search.substring(1);
            var qs = parse_query_string(query);
            if(typeof qs !== 'undefined' && qs['corona_discount']){
                if(qs['corona_discount']=="yes"){
                    var discount = "corona_discount";
                    console.log("discount");
                }
            }
        }
        $.get('/time-schedule', { 'pickup': val, 'airbnb': '0', 'wash': wash[globalData['goBack']], 'discount': discount  }, function (d) {

            // DropOff = JSON.parse(d.replace(/\,\]/gm, "]"));
            var DropOff = d;

            var options = '';
            for (var i in DropOff) options += '<option value="' + i + '">' + DropOff[i]["day_info"]["text"] +'</option>';
            $('#dropoff_date').html(options);
            //styledSelector.call($('.time-page-form [name="day-end"]')[0]);
            options = '';
            for (var i in DropOff[0]["work_times"]) options += '<option value="' + DropOff[0]["day_info"]["value"] + ' ' + DropOff[0]["work_times"][i]["value"] + ' ">' + DropOff[0]["work_times"][i]["text"] +'</option>';
            $('#dropoff_time').html(options);
            //styledSelector.call($('.time-page-form [name="time-end"]')[0]);
        });
    },
}
