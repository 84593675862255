export default {

    init(){
        this.selectSection();
        this.viewAnswer();
    },

    selectSection(){
        $('.name-section-btn').click(function() {
            var section = $(this).attr('id');
            $('.active').addClass('none').removeClass('active');
            $('.'+section).removeClass('none').addClass('active');
            if (this.classList.contains('focus')) {

            } else {
                $('.focus').removeClass('focus');
                $(this).addClass('focus');
            }
        });
        $('.faq-page-navigation-select').change(function () {
            var section = this.value;
            $('.active').addClass('none').removeClass('active');
            $('.'+section).removeClass('none').addClass('active');
        });
    },

    viewAnswer(){
        $('.view-answer-btn').click(function() {
            if(this.classList.contains('open')){
                this.classList.remove('open');
                this.parentElement.children[2].classList.remove('open');
            }else{
                this.classList.add('open');
                this.parentElement.children[2].classList.add('open');
            }
        });
    },
}
